import Image from "next/image";
import style from "./style.module.css";
import whatsapp from "../../assets/images/whatsapp.png";
import { useRouter } from 'next/router';

const Whatsapp=()=>{
    const router = useRouter();
    const hiddenButtonUrl = '/upsc-digital-lead';
    const shouldShowButton = router.pathname !== hiddenButtonUrl;
    const buttonStyle = {
        display: 'block',
      };
    
      const hiddenStyle = {
        display: 'none',
      };
     
    return(
        <>
       <div style={ shouldShowButton ? buttonStyle  : hiddenStyle }>
        <a className={`${style.linkfont}`} href="https://api.whatsapp.com/send?phone=919003190030&text=Hello!" target="blank"><button className={`${style.button}`} id={style.fixedbutton}><Image src={whatsapp} className="img-fluid align-middle me-2" alt="Whatsapp" />How can we help you?</button></a>
        </div>

        {/* <div className="d-block d-md-none">
        <a className={`${style.linkfont}`} href="https://api.whatsapp.com/send?phone=919003190030&text=Hello!" target="blank"><button className={`${style.button}`} id={style.fixedbutton}><Image src={whatsapp} className="img-fluid align-middle me-2" alt="Whatsapp" />How can we help you?</button></a>
        </div> */}
        </>
    )
}
export default Whatsapp;